import appReducers from '@app/reducers'
import { combineReducers } from 'redux'
import app from './app/reducer'
// import auth from './auth/reducer'
// import currencies from './currencies/reducer'
// import currencyBucket from './currencyBucket/reducer'
// import lists from './lists/reducer'
// import markets from './markets/reducer'
// import me from './me/reducer'
// import orders from './orders/reducer'
// import wallet from './wallet/reducer'

const AppReducer = combineReducers({
  ...appReducers,
  app,
  // auth,
  // me,
  // lists,
  // currencies,
  // currencyBucket,
  // markets,
  // wallet,
  // orders,
})

export type ReduxState = ReturnType<typeof AppReducer>

export default AppReducer
