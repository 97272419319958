import apiClient from '@app/api/apiClient'
import { DispatcherResponse } from '@typings/ReduxDispatch'
import { remoteApi, apiClient as userinoApiClient, localAuthApi, firebaseApi } from '@userino/user-api'
import moment from 'moment'
import invariant from 'tiny-invariant'
import apiWalletClient from '../../libs/api-wallet-client/client'
import appActions from '../app/actions'
import { actions } from './reducer'

class Dispatch {
  setToken(authData: { token: string }): DispatcherResponse {
    return async (dispatch) => {
      await dispatch(actions.setToken(authData.token))
      await dispatch(this.useCredentials())
    }
  }

  useCredentials(): DispatcherResponse {
    return async (dispatch, getState) => {
      let { token } = getState().auth

      apiClient.defaults.headers.common['Authorization'] = token
      apiWalletClient.defaults.headers.common['Authorization'] = token
      userinoApiClient.defaults.headers.common['Authorization'] = token
      // apiClientAssets.defaults.headers.common['Authorization'] = token
    }
  }

  loginWithFirebaseToken(firebaseToken: string, device: { id: string } & Record<string, any>): DispatcherResponse {
    console.log('loginWithFirebaseToken', firebaseToken)
    return async (dispatch) => {
      const data = await firebaseApi.login({ accessToken: firebaseToken, device })
      console.log('loginWithFirebaseToken res', data)

      await dispatch(this.setToken(data))
    }
  }

  loginWithEmailAndPassword({
    email,
    password,
    device,
  }: {
    email: string
    password: string
    device: { id: string } & Record<string, any>
  }): DispatcherResponse {
    return async (dispatch) => {
      const data = await localAuthApi.login({ email, password, device })
      console.log('loginWithEmailAndPassword res', data)

      await dispatch(this.setToken(data))

      // await dispatch(MeDispatcher.getMySelf())
      return data
    }
  }

  signupWithEmailAndPassword({
    email,
    password,
    device,
  }: {
    email: string
    password: string
    device: { id: string } & Record<string, any>
  }): DispatcherResponse {
    return async (dispatch) => {
      const data = await localAuthApi.signUp({ email, password })
      console.log('signupWithEmailAndPassword res', data)

      await dispatch(this.loginWithEmailAndPassword({ email, password, device }))

      // await dispatch(MeDispatcher.getMySelf())
      return data
    }
  }

  logout(): DispatcherResponse {
    console.log('logout()')
    return async (dispatch) => {
      dispatch(appActions.resetStore())
      delete apiClient.defaults.headers.common['Authorization']
      delete apiWalletClient.defaults.headers.common['Authorization']
      delete userinoApiClient.defaults.headers.common['Authorization']
      // window.location.reload()
    }
  }

  createRemoteLogin(): DispatcherResponse {
    return async (dispatch) => {
      const { id } = await remoteApi.create()
      invariant(id, 'invalid session response')
      dispatch(actions.setRemoteSession(id))
    }
  }

  verifyRemoteSession({ device }: { device: any}): DispatcherResponse {
    return async (dispatch, getState) => {
      const { auth } = getState()
      if (!auth.remoteSessionId) return null
      console.log('verifyRemoteSession', auth)

      const data = await remoteApi.verify(auth.remoteSessionId, { device })
      console.log('verify', moment().toISOString(), data)

      if (data.token) {
        await dispatch(this.setToken({ token: data.token }))
      }
    }
  }

  // acceptUserAgreement(): DispatcherResponse {
  //   return async (dispatch) => {
  //     await apiAuth.acceptUserAgreement()
  //     dispatch(actions.acceptTerms())
  //   }
  // }
}

const AuthDispatcher = new Dispatch()
export default AuthDispatcher
