// import MeDispatcher from '../me/dispatcher'
import MeDispatcher from '@app/reducers/me/dispatcher'
import { initDevice } from '@userino/user-api'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import { actions } from './reducer'

class Dispatcher {
  install(): DispatcherResponse {
    return async (dispatch, getState) => {
      // todo looks like it called many times
      // await dispatch(MeDispatcher.getMySelf())
      // await dispatch(CompanyDispatcher.getCompany())
      await dispatch(actions.initBrowser())
      initDevice({
        deviceType: 'web',
        deviceId: getState().app.browserId,
      })

      dispatch(this.installLazy())
    }
  }

  installLazy(): DispatcherResponse {
    return async (dispatch, getState) => {
      const { token } = getState().auth
      if (!token) return null
      await dispatch(MeDispatcher.getMe())
    }
  }
}

const AppDispatcher = new Dispatcher()
export default AppDispatcher
