import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import { reducer as appReducer, State as AppState, initialState as appInitialState } from '@app/reducers/app/reducer'

export const BROWSER_ID = 'BROWSER_ID'

interface State extends AppState {
  browserId: string
}

const initialState: State = {
  ...appInitialState,
  browserId: '',
}
const slice = createSlice({
  name: 'APP',
  initialState,
  extraReducers: builder => {
    // @ts-ignore
    builder.addDefaultCase(appReducer)
  },
  reducers: {
    initBrowser(state) {
      let browserId = localStorage.getItem(BROWSER_ID)
      if(!browserId) {
        browserId = uuid()
        localStorage.setItem(BROWSER_ID, browserId)
      }

      state.browserId = browserId
    }
  },
})

export const { reducer, actions } = slice
export default reducer
