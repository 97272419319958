import {DispatcherResponse} from "@typings/ReduxDispatch";
import AuthDispatcher from "@app/reducers/auth/dispatcher";
import dayjs from "dayjs";
import dayJsUtc from 'dayjs/plugin/utc'
import dayJsTimezone from 'dayjs/plugin/timezone'

dayjs.extend(dayJsUtc)
dayjs.extend(dayJsTimezone)


class Dispatcher {

  getDeviceInfo(): DispatcherResponse {
    return async (dispatch, getState) => {
      const { browserId } = getState().app
      const browser = {
        id: browserId,
        language: navigator.language,
        languages: navigator.languages,
        time_zone: dayjs.tz.guess()
      }

     return browser
    }
  }
  loginWithFirebaseToken(firebaseToken: string): DispatcherResponse {
    return async (dispatch) => {
      const browser = await dispatch(this.getDeviceInfo())

      await dispatch(AuthDispatcher.loginWithFirebaseToken(firebaseToken, browser))
    }
  }

  signupWithEmailAndPassword({
                               email,
                               password,
   }: {
    email: string
    password: string
  }): DispatcherResponse {
    return async (dispatch) => {
      const browser = await dispatch(this.getDeviceInfo())

      const data = await dispatch(AuthDispatcher.signupWithEmailAndPassword({ password, device: browser, email }))
      return data
    }
  }


  loginWithEmailAndPassword({
       email,
     password,
   }: {
    email: string
    password: string
  }): DispatcherResponse {
    return async (dispatch) => {
      const browser = await dispatch(this.getDeviceInfo())

      const data = await dispatch(AuthDispatcher.loginWithEmailAndPassword({ password, device: browser, email }))
      return data
    }
  }

  verifyRemoteSession(): DispatcherResponse {
    return async (dispatch) => {
      const browser = await dispatch(this.getDeviceInfo())


      const data = await dispatch(AuthDispatcher.verifyRemoteSession({ device: browser }))
      return data
    }
  }
}

const AuthWebDispatcher = new Dispatcher()
export default AuthWebDispatcher